<div
    class="tw-flex tw-justify-between tw-gap-4 tw-p-8 tw-flex-row tw-bg-white tw-rounded-lg tw-border tw-border-ui-grey-89 tw-border-solid">
    <div class="tw-flex tw-flex-col tw-w-full">
        <mat-form-field
            appearance="outline"
            class="tw-w-full">
            <mat-label>Select field</mat-label>
            <input
                matInput
                [value]="creativeField"
                readonly="true" />
        </mat-form-field>
        <div class="tw-mt-4 tw-flex tw-items-center tw-gap-2">
            <span class="tw-whitespace-nowrap">Use as</span>
            <mat-form-field
                appearance="outline"
                class="tw-w-full">
                <input
                    matInput
                    readonly="true"
                    value="Default creative" />
            </mat-form-field>
        </div>
    </div>
    <div class="tw-flex tw-self-start tw-w-full">
        <mat-form-field
            appearance="outline"
            class="tw-w-full">
            <mat-label>Select creative</mat-label>
            <input
                matInput
                [value]="inputValue"
                readonly />
            <button
                mat-button
                matSuffix
                (click)="openCreativeSelector($event)"
                class="tw-text-ui-primary tw-font-medium tw-bg-transparent tw-border-none tw-cursor-pointer tw-px-4">
                SELECT
            </button>
        </mat-form-field>
    </div>
    <div class="tw-flex tw-justify-center tw-items-center">
        @if (selectedCreative$ | async; as selectedCreative) {
            <creative-preview [selectedCreative]="selectedCreative" />
        } @else {
            <div class="tw-w-[300px] tw-aspect-square tw-bg-ui-waterfall">
                <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                    <ui-svg-icon
                        icon="creative-set"
                        class="icon" />
                </div>
            </div>
        }
    </div>
    <bottom-navigation>
        <div class="tw-flex tw-gap-6 tw-pr-5 tw-grow-1 tw-justify-end">
            <p class="tw-text-ui-primary">
                <ui-svg-icon
                    [icon]="'info-l'"
                    class="info" />This step can be skipped and completed later
            </p>
            <ui-button
                type="primary"
                text="CUSTOMIZE FEED"
                (click)="stepperService.completeStep()"></ui-button>
        </div>
    </bottom-navigation>
</div>

<ui-dialog
    #selectCreativeDialog="ui-dialog"
    [config]="{
        headerText: 'Select Creative',
        maxWidth: 1200,
        maxHeight: 1000,
        padding: 0,
        panelClass: 'tw-overflow-hidden'
    }">
    <ng-template ui-dialog-template>
        <creative-selector
            (closeDialog)="selectCreativeDialog.close()"
            (selectedCreative)="onCreativeSelected($event)"></creative-selector>
    </ng-template>
</ui-dialog>

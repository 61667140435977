import { Component, inject, Input, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UICreativePreviewComponent, UIDialogDirective, UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { BottomNavigationComponent } from '../bottom-navigation/bottom-navigation.component';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
import { CreativeSelectorComponent } from '../creative-selector/creative-selector.component';
import { StudioService } from 'src/app/services/api/studio.service';
import { Subject, switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'default-creative-card',
    standalone: true,
    imports: [
        UIModule,
        MatInputModule,
        MatFormFieldModule,
        CreativeSelectorComponent,
        CreativePreviewComponent,
        BottomNavigationComponent,
        UICreativePreviewComponent,
        AsyncPipe
    ],
    templateUrl: './default-creative-card.component.html',
    styleUrl: './default-creative-card.component.scss'
})
export class DefaultCreativeCardComponent {
    @Input() creativeField: string = 'image_link';
    @ViewChild('selectCreativeDialog') selectCreativeDialog: UIDialogDirective;
    stepperService = inject(StepperService);
    studioService = inject(StudioService);

    selectedCreative$ = new Subject<Creative>();
    inputValue = '';

    dynamicFields$ = this.selectedCreative$.pipe(
        switchMap(creative => this.studioService.getDynamicFieldsByCreativeId(creative.id))
    );

    openCreativeSelector(event: MouseEvent): void {
        event.stopPropagation();
        this.selectCreativeDialog.open();
    }

    onCreativeSelected(creative: Creative): void {
        this.selectedCreative$.next(creative);
        this.inputValue = `${creative!.size.width} x ${creative!.size.height}`;
    }
}
